import { defineComponent } from 'vue';
export default defineComponent({
    name: 'expand',
    props: {
        text: {
            default: '',
        },
        initStrLength: {
            type: Number,
            default: 4
        }
    },
    data() {
        return {
            id: (new Date()).getTime()
        };
    },
});
