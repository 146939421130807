import { Picture } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import DownloadAttachment from '@/core/components/common/attachments/DownloadAttachment.vue';
import EditCardBox from '@/core/components/ui/EditCardBox.vue';
import { ImageFileTypes } from '@/core/helpers/file.helper';
import StringHelper from '@/core/helpers/string.helper';
import { ProjectJobStatusEnum, ProjectStatusesEnum } from '@/core/packages/shared-library';
import ProjectAttachmentsForm from '@/modules/project-overview/components/ProjectAttachmentsForm.vue';
import ProjectDescriptionForm from '@/modules/project-overview/components/ProjectDescriptionForm.vue';
import ProjectInformationForm from '@/modules/project-overview/components/ProjectInformationForm.vue';
import ProjectMaterialsForm from '@/modules/project-overview/components/ProjectMaterialsForm.vue';
import ProjectStatusForm from '@/modules/project-overview/components/ProjectStatusForm.vue';
import { ATTACHMENT_CACHE_STORE } from '@/store/modules/attachment-cache';
import { PROJECT_QUOTES_CACHE, PROJECTS_CACHE } from '@/store/modules/attachment-cache/constants';
import { PROJECTS_STORE } from '../../../store/modules/projects';
export default defineComponent({
    name: 'project-details',
    emits: [
        'do-update',
        'enable-edit-project-details',
        'cancel-edit-project-details',
        'enable-edit-project-description',
        'cancel-edit-project-description',
        'enable-edit-project-status',
        'cancel-edit-project-status',
        'enable-edit-project-materials',
        'cancel-edit-project-materials',
        'enable-edit-project-attachments',
        'cancel-edit-project-attachments',
        'navigate-material-url'
    ],
    components: {
        EditCardBox,
        ProjectDescriptionForm,
        ProjectInformationForm,
        ProjectStatusForm,
        ProjectMaterialsForm,
        ProjectAttachmentsForm,
        DownloadAttachment,
        Picture
    },
    props: {
        project: Object,
        projectQuote: Object,
        /* Project Attachment Grid */
        projectAttachmentGridLg: {
            type: Number,
            default: 8
        },
        projectAttachmentGridMd: {
            type: Number,
            default: 8
        },
        projectAttachmentGridSm: {
            type: Number,
            default: 12
        },
        projectAttachmentGridXs: {
            type: Number,
            default: 24
        },
        submitting: {
            type: Boolean,
            default: false
        },
        editProjectDetails: {
            type: Boolean,
            default: false
        },
        editProjectDescription: {
            type: Boolean,
            default: false
        },
        editProjectStatus: {
            type: Boolean,
            default: false
        },
        editProjectMaterials: {
            type: Boolean,
            default: false
        },
        editProjectAttachments: {
            type: Boolean,
            default: false
        },
        canEditExistingProject: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            selectedMaterialUrl: null,
            selectedAttachmentUrl: null,
            apiUrl: process.env.VUE_APP_API_URL,
            originalProjectData: null,
            projectAttachments: [],
            projectAttachmentThumbs: [],
            isAttachmentRequestComplete: true,
            projectQuoteAttachments: [],
            projectImageAttachments: [],
            categoryListing: [],
            viewMore: false
        };
    },
    mounted() {
        this.originalProjectData = { ...this.project };
    },
    created() {
        this.setCategoryListing();
        this.loadAttachments();
    },
    computed: {
        ...mapGetters(['isTradesperson', 'isProjectOwner']),
        ...mapGetters(PROJECTS_STORE, ['materialAvailabilityLabel']),
        projectConfimed() {
            return ([
                ProjectJobStatusEnum.QUOTE_CONFIRMED,
                ProjectJobStatusEnum.IN_PROGRESS,
                ProjectJobStatusEnum.COMPLETED
            ].indexOf(this.project.projectJobStatus.id) !== -1);
        },
        projectMaterial() {
            return this.materialAvailabilityLabel.find((material) => {
                return material.value === this.project.materials;
            });
        },
        previewImages() {
            return this.projectImageAttachments.map((attachment) => attachment.src);
        },
        materialUrls() {
            const { materialUrls } = this.project;
            const isValidData = StringHelper.IsJsonString(materialUrls);
            const list = [];
            if (isValidData) {
                const currentList = JSON.parse(materialUrls);
                for (let i = 0; i < currentList.length; i++) {
                    const item = currentList[i];
                    if (item.url) {
                        list.push(item);
                    }
                }
            }
            return list;
        },
        projectAttachmentUrls() {
            const { attachmentUrls } = this.project;
            const isValidData = StringHelper.IsJsonString(attachmentUrls);
            const list = [];
            if (isValidData) {
                const currentList = JSON.parse(attachmentUrls);
                for (let i = 0; i < currentList.length; i++) {
                    const item = currentList[i];
                    if (item.url) {
                        list.push(item);
                    }
                }
            }
            return list;
        },
        viewMoreLabel() {
            return !this.viewMore ? 'See more' : 'See less';
        }
    },
    methods: {
        ...mapActions(ATTACHMENT_CACHE_STORE, ['getAttachments', 'resetCacheEntity']),
        ...mapActions(PROJECTS_STORE, ['getProjectByRefId']),
        setCategoryListing(limitToShow = 2) {
            const { project } = this;
            const { categories } = project || [];
            if (categories.length) {
                this.categoryListing = categories.slice(0, limitToShow);
            }
        },
        viewMoreCategoryListing() {
            const { project } = this;
            const { categories } = project || [];
            if (!this.viewMore) {
                this.setCategoryListing(categories.length);
            }
            else {
                this.setCategoryListing();
            }
            this.viewMore = !this.viewMore;
        },
        getCategoryLabel(category) {
            const label = this.isTradesperson ? category.tpLabel : category.poLabel;
            return label.trim();
        },
        getSubCategories(categoryIndex) {
            const { project } = this;
            const { categories } = project || [];
            let subCategories = [];
            if (categories) {
                subCategories = categories[categoryIndex]?.subCategories || [];
            }
            return subCategories;
        },
        doUpdateProjectDetails(formType) {
            this.$emit('do-update', {
                formData: {
                    name: this.project.name,
                    postcode: this.project.postcode,
                    categories: this.project.categoriesForSubmission
                },
                formType,
                cb: async (project) => {
                    this.originalProjectData = project;
                }
            });
            delete this.project.categoriesForSubmission;
        },
        doUpdateProjectDescription(formType) {
            this.$emit('do-update', {
                formData: { description: this.project.description },
                formType
            });
            this.originalProjectData = { ...this.project };
        },
        doUpdateProjectStatus(formType) {
            const formData = {
                projectStatusId: this.project.projectStatusId,
                hasPreferredSchedule: this.project.hasPreferredSchedule
            };
            if (this.project.hasPreferredSchedule
                && this.project.projectStatusId === ProjectStatusesEnum.READY_FOR_HIRE) {
                formData.startDate = this.project.startDate;
                formData.endDate = this.project.endDate;
            }
            else {
                this.project.startDate = null;
                this.project.endDate = null;
            }
            this.$emit('do-update', { formData, formType });
            this.originalProjectData = { ...this.project };
        },
        doUpdateProjectMaterials(formType) {
            const newMaterialUrls = this.project.materialUrls;
            this.project.materialUrls = JSON.stringify(this.project.materialUrls);
            this.$emit('do-update', {
                formData: {
                    materials: this.project.materials,
                    materialDescription: this.project.materialDescription,
                    materialUrls: newMaterialUrls
                },
                formType
            });
            this.originalProjectData = { ...this.project };
        },
        doUpdateProjectAttachments(formType) {
            const newAttachmentUrls = this.project.attachmentUrls;
            const attachments = this.cleanAttachmentData(this.project.attachments);
            this.project.attachmentUrls = JSON.stringify(this.project.attachmentUrls);
            this.$emit('do-update', {
                formData: {
                    attachmentUrls: newAttachmentUrls,
                    attachments
                },
                formType,
                cb: async (data) => {
                    this.resetCacheEntity(PROJECTS_CACHE);
                    await this.loadAttachments(data);
                }
            });
            this.originalProjectData = { ...this.project };
        },
        enableEditProjectDetails() {
            this.$emit('enable-edit-project-details');
        },
        cancelEditProjectDetails() {
            this.project.name = this.originalProjectData.name;
            this.project.categories = this.originalProjectData.categories;
            this.$emit('cancel-edit-project-details');
        },
        enableEditProjectDescription() {
            this.$emit('enable-edit-project-description');
        },
        cancelEditProjectDescription() {
            this.project.description = this.originalProjectData.description;
            this.$emit('cancel-edit-project-description');
        },
        enableEditProjectStatus() {
            this.$emit('enable-edit-project-status');
        },
        cancelEditProjectStatus() {
            this.project.projectStatus.id = this.originalProjectData.projectStatus.id;
            this.project.hasPreferredSchedule = this.originalProjectData.hasPreferredSchedule;
            this.$emit('cancel-edit-project-status');
        },
        enableEditProjectMaterials() {
            this.$emit('enable-edit-project-materials');
        },
        cancelEditProjectMaterials() {
            this.project.materials = this.originalProjectData.materials;
            this.project.materialDescription = this.originalProjectData.materialDescription;
            this.project.materialUrls = this.originalProjectData.materialUrls;
            this.$emit('cancel-edit-project-materials');
        },
        enableEditProjectAttachments() {
            this.$emit('enable-edit-project-attachments');
        },
        cancelEditProjectAttachments() {
            this.project.attachmentUrls = this.originalProjectData.attachmentUrls;
            this.$emit('cancel-edit-project-attachments');
        },
        async loadAttachments(data = null) {
            await this.loadProjectAttachments(data);
            await this.loadQuoteAttachments();
        },
        getProjectAttachmentId(attachment, data) {
            const { project } = this;
            let { projectAttachments } = project;
            if (data) {
                projectAttachments = data.projectAttachments;
            }
            return projectAttachments.filter((item) => {
                return item.attachment === attachment;
            });
        },
        cleanAttachmentData(attachments) {
            return attachments.map((attachment) => {
                const current = attachment;
                delete current.src;
                delete current.isDeleting;
                delete current.isLoading;
                return {
                    ...current
                };
            });
        },
        async loadProjectAttachments(data = null) {
            const props = this.$props;
            const { project } = props;
            let { projectAttachments } = project;
            if (data) {
                projectAttachments = data.projectAttachments;
            }
            this.isAttachmentRequestComplete = false;
            this.resetCacheEntity(PROJECTS_CACHE);
            await this.getAttachments({
                name: PROJECTS_CACHE,
                attachments: projectAttachments,
            })
                .then((values) => {
                const newValues = values.filter(Boolean);
                if (newValues.length) {
                    this.projectAttachments = newValues.map((item) => {
                        const attachment = this.getProjectAttachmentId(item.attachment, data);
                        return {
                            ...item,
                            id: attachment[0]?.id
                        };
                    });
                    this.projectImageAttachments = this.projectAttachments.filter((item) => {
                        return this.isImageAttachment(item.originalName);
                    });
                }
                this.isAttachmentRequestComplete = true;
            })
                .catch(() => { })
                .finally(() => { this.isAttachmentRequestComplete = true; });
        },
        async loadQuoteAttachments() {
            const { projectQuote } = this;
            if (projectQuote) {
                const { projectQuoteAttachments } = projectQuote;
                if (projectQuoteAttachments?.length) {
                    this.isAttachmentRequestComplete = false;
                    await this.getAttachments({
                        name: PROJECT_QUOTES_CACHE,
                        attachments: projectQuoteAttachments,
                        includeUrl: true
                    })
                        .then((values) => {
                        const newValues = values.filter(Boolean);
                        if (newValues.length) {
                            this.projectQuoteAttachments = newValues;
                        }
                        this.isAttachmentRequestComplete = true;
                    })
                        .catch(() => { })
                        .finally(() => { this.isAttachmentRequestComplete = true; });
                }
            }
        },
        isImageAttachment(filename) {
            const extension = filename.split('.')[filename.split('.').length - 1];
            return ImageFileTypes.includes(extension.toLowerCase());
        },
    }
});
