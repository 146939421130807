import { defineComponent } from '@vue/runtime-core';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { ProjectStatusesEnum } from '@/core/packages/shared-library';
import { PROJECTS_STORE } from '../../../store/modules/projects';
export default defineComponent({
    name: 'project-status-form',
    props: {
        project: {
            type: Object,
            required: true,
        }
    },
    data: () => {
        return {
            ProjectStatusesEnum,
            dates: [],
            formRules: {
                dates: [
                    { required: false, message: 'Project Schedule is required.', trigger: 'change' }
                ],
                projectStatusId: [
                    { required: true, message: 'Project status is required.', trigger: 'change' },
                ],
            }
        };
    },
    async mounted() {
        await this.getProjectStatuses();
        if (this.formEntity.startDate && this.formEntity.endDate) {
            this.dates = [this.formEntity.startDate, this.formEntity.endDate];
        }
    },
    watch: {
        dates: {
            handler(newval) {
                if (newval.length) {
                    const [startDate, endDate] = newval;
                    this.formEntity.startDate = startDate;
                    this.formEntity.endDate = endDate;
                }
            }
        },
        'formEntity.projectStatusId': {
            handler() {
                this.formEntity.hasPreferredSchedule = true;
                this.dates = [];
            }
        }
    },
    computed: {
        formEntity() {
            return this.project;
        },
        // eslint-disable-next-line func-names
        'formEntity.projectStatusId': function () {
            return this.formEntity.projectStatus.id;
        },
        ...mapGetters(PROJECTS_STORE, ['projectStatuses']),
        setChooseDay() {
            return {
                disabledDate(date) {
                    return moment(date).utc() < moment().startOf('day');
                }
            };
        },
    },
    methods: {
        ...mapActions(PROJECTS_STORE, ['getProjectStatuses']),
        trimField(val, field) {
            this.formEntity[field] = val ? val.trim() : val;
        },
        clearSchedule() {
            this.dates = [];
        }
    }
});
