<template>
  <div class="image-dynamic-layout">
    <template v-if="isLoading">
      <ImageUploading />
    </template>
    <template v-else-if="isDeleting">
      <ImageDeleting />
    </template>
    <template v-else-if="!isLoading">
      <slot></slot>
    </template>
  </div>
</template>
<script>
import ImageDeleting from '@/core/components/ui/ImageDeleting.vue';
import ImageUploading from '@/core/components/ui/ImageUploading.vue';

export default {
  name: 'image-dynamic-layout',

  props: {
    isLoading: Boolean,

    isDeleting: Boolean,

    source: String
  },

  components: {
    ImageUploading,
    ImageDeleting
  }
};
</script>
<style lang="scss" scoped>

</style>
