import { CirclePlus } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { MaterialAvailabilityEnum, MaterialAvailabilityLabelEnum } from '@/core/packages/shared-library';
export default defineComponent({
    name: 'project-status-form',
    components: {
        CirclePlus
    },
    props: {
        project: {
            type: Object,
            required: true,
        }
    },
    data: () => {
        const validateDescription = (rule, value, callback) => {
            if (value === '' && this.formEntity.materials === MaterialAvailabilityEnum.HAVE_MATERIALS) {
                callback(new Error('Please enter materials description.'));
            }
            else {
                callback();
            }
            callback();
        };
        return {
            formRules: {
                description: [
                    {
                        validator: validateDescription, trigger: 'blur'
                    }
                ]
            }
        };
    },
    mounted() {
        this.formEntity.materialUrls = typeof this.formEntity.materialUrls === 'string'
            ? JSON.parse(this.formEntity.materialUrls) : this.formEntity.materialUrls;
    },
    computed: {
        formEntity() {
            return this.project;
        },
        materialAvailabilityOptions() {
            const materialAvailabilityOptions = [];
            for (const [idx, MaterialAvailabilityOption] of Object.entries(MaterialAvailabilityLabelEnum)) {
                materialAvailabilityOptions.push({
                    label: MaterialAvailabilityOption,
                    value: MaterialAvailabilityEnum[idx],
                });
            }
            return materialAvailabilityOptions;
        }
    },
    methods: {
        addMoreMaterialUrl() {
            if (!this.formEntity.materialUrls) {
                this.formEntity.materialUrls = [];
                const materialUrl = { url: '' };
                this.formEntity.materialUrls.push(materialUrl);
            }
            else if (this.formEntity.materialUrls?.length <= 8) {
                const materialUrl = { url: '' };
                this.formEntity.materialUrls.push(materialUrl);
            }
            else {
                this.$notify.error({
                    title: 'Error Material Urls',
                    message: 'You reached the max limit of material urls.'
                });
            }
        },
    }
});
