import { defineComponent } from 'vue';
export default defineComponent({
    name: 'project-description-form',
    props: {
        project: {
            type: Object,
            required: true,
        }
    },
    data: () => {
        return {
            editProject: null
        };
    },
    computed: {
        formEntity() {
            return this.project;
        }
    },
    methods: {
        trimField(val, field) {
            this.formEntity[field] = val ? val.trim() : val;
        },
    }
});
