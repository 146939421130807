<template>
  <div class="image-failed">
    <span class="container">
      <el-icon><Picture /></el-icon>
      <br>
      <!-- <span class="label">FAILED</span> -->
    </span>
  </div>
</template>
<script>
import { Picture } from '@element-plus/icons';

export default {
  name: 'image-failed',

  components: {
    Picture
  }
};
</script>
<style lang="scss" scoped>
  .image-failed {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .container {
      text-align: center;
    }

    i {
      font-size: 4em;
    }

    .label {
      font-weight: bold;
      font-size: 10px;
    }
  }
</style>
