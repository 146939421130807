<template>
  <section class="message-list-filter padding-vertical-16">
    <el-input prefix-icon="el-icon-search" placeholder="Enter text to search..." v-model="searchMessageList" v-debounce:150="onSearch">
      <template #prefix>
        <el-icon class="el-input__icon"><Search /></el-icon>
      </template>
    </el-input>
  </section>
</template>
<script>
import { Search } from '@element-plus/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'message-list-filter-component',
  components: {
    Search
  },
  emits: ['filter'],
  data: () => {
    return {
      searchMessageList: ''
    };
  },
  methods: {
    onSearch() {
      this.$emit('filter', this.searchMessageList);
    }
  }
});
</script>
<style lang="scss" scoped>
.message-list-filter {
  position: relative;
  max-height: 65px;
  height: 47px;

  :deep(.el-input__inner) {
    background: rgba(12, 15, 74, 0.05);
    border: none;
    color: #0c0f4a;
    border-radius: 30px;
    font-size: 14px;
    padding: 20px 20px 20px 45px;
    font-family: Arial;
  }

  :deep(.el-input__prefix) {
    left: 15px;
    color: #0c0f4a;
    top: -2px;
  }
}
</style>
