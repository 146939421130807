import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import MessageListFilterComponent from './MessageListFilterComponent.vue';
import PoMessageListItemComponent from './PoMessageListItemComponent.vue';
import TpMessageListItemComponent from './TpMessageListItemComponent.vue';
export default defineComponent({
    name: 'message-list-menu-component',
    components: {
        MessageListFilterComponent,
        PoMessageListItemComponent,
        TpMessageListItemComponent,
    },
    props: {
        userMessageRooms: Array,
        selectedUserMessageRoom: Object,
    },
    emits: ['filter-user-message-rooms', 'select-user-message-room', 'view-contacts'],
    computed: {
        ...mapGetters(['isTradesperson', 'isProjectOwner']),
    },
    methods: {
        onChangeFilterUserMessageRooms(searchStr) {
            this.$emit('filter-user-message-rooms', searchStr);
        },
        selectUserMessageRoom(userMessageRoom) {
            this.$emit('select-user-message-room', userMessageRoom);
            this.$emit('view-contacts');
        }
    }
});
