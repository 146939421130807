import { ArrowLeft, Check, Document, Loading, Picture } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import MessageInputWidgetsComponent from '@/core/components/messages/MessageInputWidgetsComponent.vue';
import MessageListDetailBoxComponent from '@/core/components/messages/MessageListDetailBoxComponent.vue';
import CardWrapper from '@/core/components/ui/CardWrapper.vue';
import Loader from '@/core/components/ui/Loader.vue';
import { MessageStateEnum } from '@/core/packages/shared-library';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';
import { ATTACHMENT_CACHE_STORE } from '../../../store/modules/attachment-cache';
import { USER_MESSAGES_CACHE } from '../../../store/modules/attachment-cache/constants';
import { ImageFileTypes } from '../../helpers/file.helper';
export default defineComponent({
    name: 'messages-list-component',
    emits: ['load-unread-messages', 'save-new-message', 'submit-attachment', 'view-contacts'],
    components: {
        CardWrapper,
        Loader,
        Check,
        Picture,
        MessageListDetailBoxComponent,
        MessageInputWidgetsComponent,
        Loading,
        Document,
        ArrowLeft
    },
    props: {
        showViewProjectButton: {
            default: false,
        },
        selectedUserMessageRoom: {
            type: Object,
            required: true,
        },
        isSearchTriggered: {
            type: Object,
            required: true
        }
    },
    data: () => {
        return {
            message: '',
            inputDisable: false,
            currentUploadedFile: null,
            attachmentUrls: [],
            isMessageAttachmentLoaded: true
        };
    },
    computed: {
        ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),
        ...mapGetters(['isTradesperson', 'isProjectOwner']),
        disableInput() {
            return this.inputDisable;
        },
        member() {
            let member = this.selectedUserMessageRoom.fromUser && this.selectedUserMessageRoom.fromUser.id !== this.authenticatedUser?.userId
                ? this.selectedUserMessageRoom.fromUser : this.selectedUserMessageRoom.toUser;
            if (!member) {
                member = this.selectedUserMessageRoom?.project?.projectCreator;
            }
            return member;
        },
        memberIsOnline() {
            if (this.selectedUserMessageRoom?.members?.length) {
                // eslint-disable-next-line radix
                return this.selectedUserMessageRoom.members.find((m) => parseInt(m.id) === this.member.id);
            }
            return false;
        },
        memberFullName() {
            return `${this.member.firstName} ${this.member.lastName}`;
        },
        getUserMessageList() {
            let userMessageList = this.selectedUserMessageRoom.userMessages;
            if (this.selectedUserMessageRoom.userMessageAttachments?.length) {
                userMessageList = userMessageList.concat(this.selectedUserMessageRoom.userMessageAttachments);
                this.scrollToBottom();
            }
            // eslint-disable-next-line no-nested-ternary
            userMessageList.sort((a, b) => ((a.dateCreated > b.dateCreated) ? 1 : ((b.dateCreated > a.dateCreated) ? -1 : 0)));
            return userMessageList;
        },
        isContactViewable() {
            const route = this.$route;
            let viewable = true;
            if (route && route.name === 'project-messages' && this.isTradesperson) {
                viewable = false;
            }
            return viewable;
        }
    },
    watch: {
        'selectedUserMessageRoom.userMessages': {
            immediate: true,
            handler(newval) {
                if (newval) {
                    this.scrollToBottom();
                }
            }
        },
        'selectedUserMessageRoom.userMessageAttachments': {
            immediate: true,
            handler(newval) {
                if (newval) {
                    this.loadAttachments();
                }
            }
        }
    },
    methods: {
        ...mapActions(ATTACHMENT_CACHE_STORE, ['getAttachments']),
        loadAttachments() {
            const { userMessageAttachments } = this.selectedUserMessageRoom;
            const attachments = userMessageAttachments;
            this.isMessageAttachmentLoaded = false;
            this.getAttachments({
                name: USER_MESSAGES_CACHE,
                attachments,
                includeUrl: true
            })
                .then((values) => {
                const newValues = values.filter(Boolean);
                if (newValues.length) {
                    this.attachmentUrls = newValues;
                    if (this.attachmentUrls?.length) {
                        this.attachmentUrls.forEach((attachmentUrl) => {
                            const findListItem = this.getUserMessageList.find((listItem) => listItem?.attachment && listItem?.attachment === attachmentUrl?.attachment);
                            if (findListItem) {
                                const indexOfFoundListItem = this.getUserMessageList.indexOf(findListItem);
                                findListItem.src = attachmentUrl.src;
                                findListItem.url = attachmentUrl.url;
                                this.getUserMessageList[indexOfFoundListItem] = findListItem;
                            }
                        });
                    }
                }
            })
                .catch(() => { })
                .finally(() => {
                this.isMessageAttachmentLoaded = true;
            });
        },
        isImageAttachment(file) {
            let { attachment: filename } = file;
            const { name } = file;
            if (!filename) {
                filename = name;
            }
            if (!filename)
                return false;
            const extension = filename.split('.')[filename.split('.').length - 1];
            return ImageFileTypes.includes(extension.toLowerCase());
        },
        isPdfFile(filename) {
            return filename && filename.indexOf('.pdf') > -1;
        },
        loadMoreMessages() {
            this.$emit('load-unread-messages');
        },
        onEnter() {
            this.submitMessage();
        },
        onClick() {
            this.submitMessage();
        },
        submitMessage() {
            const message = {
                messageId: `${(new Date()).getTime()}`,
                content: this.message,
                userMessageRoomId: this.selectedUserMessageRoom.id,
                fromUser: this.authenticatedUser?.userId,
                toUser: this.selectedUserMessageRoom?.toUser?.id !== this.authenticatedUser?.userId
                    ? this.selectedUserMessageRoom?.toUser?.id : this.selectedUserMessageRoom?.fromUser?.id,
                state: MessageStateEnum.UNREAD,
            };
            if (message.content) {
                this.$emit('save-new-message', message);
                this.message = '';
                this.scrollToBottom();
            }
        },
        uploadingAttachmentOnProgress(event, file) {
            this.currentUploadedFile = {
                file,
                event,
            };
        },
        doSubmitUploadedAttachment(file) {
            this.$emit('submit-attachment', file, (response) => {
                if (response) {
                    const fileName = this.currentUploadedFile?.file?.name;
                    this.$notify.success({
                        message: `You successfully uploaded "${fileName}".`
                    });
                    this.currentUploadedFile = null;
                }
            });
        },
        scrollToBottom() {
            setTimeout(() => {
                const element = this.$refs[`message-items-wrapper-${this.selectedUserMessageRoom.project.refId}`];
                if (element) {
                    element.scrollTop = element?.scrollHeight;
                }
            }, 100);
        },
        viewContacts() {
            this.$emit('view-contacts');
        }
    },
});
