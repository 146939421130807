import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import Expand from '@/core/components/ui/Expand.vue';
import { ProjectJobStatusEnum } from '@/core/packages/shared-library';
import appFilters from '@/filters';
import { AUTHENTICATION_STORE } from '../../../store/modules/authentication';
export default defineComponent({
    name: 'tp-message-list-item-component',
    components: {
        Expand
    },
    emits: ['select-user-message-room'],
    props: {
        userMessageRoom: Object,
        selectedUserMessageRoom: Object,
    },
    data() {
        return {
            ProjectJobStatusEnum,
            projectAttachmentThumbnail: null,
        };
    },
    created() {
        this.loadProjectThumbnail();
    },
    computed: {
        ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),
        getUnreadMessages() {
            if (this.userMessageRoom.userMessages?.length) {
                const unreadMessages = this.userMessageRoom.userMessages.filter((m) => m.state === 'unread'
                    && (m.fromUser.id !== this.authenticatedUser?.userId));
                return unreadMessages.length;
            }
            return 0;
        },
        member() {
            const member = this.userMessageRoom.fromUser && this.userMessageRoom.fromUser.id !== this.authenticatedUser?.userId
                ? this.userMessageRoom.toUser : this.userMessageRoom.fromUser;
            return member;
        },
    },
    methods: {
        async loadProjectThumbnail() {
            const { projectAttachments } = this.userMessageRoom?.project;
            if (projectAttachments?.length) {
                const [firstProjectAttachment] = projectAttachments;
                if (firstProjectAttachment) {
                    this.projectAttachmentThumbnail = await appFilters.formatToImageSourceUrl(firstProjectAttachment.attachment);
                }
            }
        },
        selectUserMessageRoom(userMessageRoom) {
            this.$emit('select-user-message-room', userMessageRoom);
        }
    }
});
