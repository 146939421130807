import { defineComponent } from '@vue/runtime-core';
import { AUTH_TOKEN } from '@/core/constants';
import { getLocalStorageWithExpiry } from '@/core/utils/common';
const ATTACHMENT_URL = 'download-attachments';
export default defineComponent({
    name: 'download-attachment',
    props: {
        link: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: false
        }
    },
    computed: {
        getDownloadLink() {
            return `${this.link}/${ATTACHMENT_URL}`;
        }
    },
    methods: {
        download() {
            const token = getLocalStorageWithExpiry(AUTH_TOKEN);
            window.open(`${this.getDownloadLink}?download=${token}`);
        }
    }
});
