import { defineComponent } from 'vue';
export default defineComponent({
    name: 'edit-card-box',
    emits: ['submit', 'edit', 'cancel'],
    props: {
        editable: Boolean,
        editmode: Boolean,
        submitting: Boolean,
    },
    methods: {
        executeAction() {
            if (this.editmode) {
                this.$confirm('Are you sure to update these details below?', 'Confirm Update Project Details', {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                    type: 'info'
                })
                    .then(() => {
                    this.$emit('submit');
                });
            }
            else {
                this.$emit('edit');
            }
        }
    }
});
