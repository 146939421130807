import { CirclePlus, Delete, Document, Plus } from '@element-plus/icons';
import ImageDynamicLayout from '@/core/components/ui/ImageDynamicLayout.vue';
import ImageFailedLoading from '@/core/components/ui/ImageFailedLoading.vue';
import ImageLoading from '@/core/components/ui/ImageLoading.vue';
import { DocumentFileTypes, ImageFileTypes } from '@/core/helpers/file.helper';
import { getLocalStorageWithExpiry } from '@/core/utils/common';
import { AUTH_TOKEN } from '../../../core/constants';
export default {
    name: 'project-attachments-form',
    props: {
        project: {
            type: Object,
            required: true,
        },
        projectAttachmentSources: {
            type: Array,
            required: true
        }
    },
    components: {
        ImageDynamicLayout,
        ImageLoading,
        ImageFailedLoading,
        CirclePlus,
        Delete,
        Document,
        Plus
    },
    data: () => {
        return {
            apiUrl: process.env.VUE_APP_API_URL,
            failedUpload: false,
            imageFileTypes: ImageFileTypes,
            documentFileTypes: DocumentFileTypes,
            fileList: [],
        };
    },
    created() {
        const component = this;
        const { formEntity, projectAttachmentSources } = component;
        formEntity.attachmentUrls = typeof component.project.attachmentUrls === 'string'
            ? JSON.parse(component.project.attachmentUrls) : component.project.attachmentUrls;
        if (projectAttachmentSources && projectAttachmentSources.length) {
            component.fileList = projectAttachmentSources.map((attachment) => {
                return {
                    ...attachment,
                    isLoading: false,
                    isDeleting: false
                };
            });
            formEntity.attachments = component.fileList;
        }
        if (!formEntity.attachments) {
            formEntity.attachments = [];
        }
    },
    computed: {
        formEntity() {
            return this.project;
        },
        uploadHeaders() {
            const token = getLocalStorageWithExpiry(AUTH_TOKEN);
            return {
                Authorization: `Bearer ${token}`
            };
        }
    },
    methods: {
        onBeforeUpload(file) {
            const name = file.name || '';
            const ext = name.split('.').pop();
            const imageTypes = this.imageFileTypes;
            const fileTypes = this.documentFileTypes;
            let allow = false;
            this.failedUpload = false;
            if (imageTypes.indexOf(ext) !== -1) {
                allow = true;
            }
            if (!allow && fileTypes.indexOf(ext) !== -1) {
                allow = true;
            }
            if (!allow) {
                this.failedUpload = true;
            }
            return allow;
        },
        fileIsImage(file) {
            let { attachment: filename } = file;
            const { name } = file;
            const imageTypes = this.imageFileTypes;
            if (!filename) {
                filename = name;
            }
            if (!filename)
                return false;
            const extension = filename.split('.')[filename.split('.').length - 1];
            return imageTypes.includes(extension.toLowerCase());
        },
        addMoreAttachmentUrl() {
            if (!this.formEntity.attachmentUrls) {
                this.formEntity.attachmentUrls = [];
                const attachmentUrl = { url: '' };
                this.formEntity.attachmentUrls.push(attachmentUrl);
            }
            else if (this.formEntity.attachmentUrls?.length <= 8) {
                const attachmentUrl = { url: '' };
                this.formEntity.attachmentUrls.push(attachmentUrl);
            }
            else {
                this.$notify.error({
                    title: 'Error Attachment Urls',
                    message: 'You reached the max limit of attachment urls.'
                });
            }
        },
        async handleRemoveFile(file) {
            const component = this;
            const currentFile = file;
            const { response } = file;
            const { formEntity, fileList } = component;
            const { attachments } = formEntity;
            const attachment = response?.attachment;
            let newList = [];
            currentFile.isDeleting = true;
            if (!attachment) {
                newList = fileList.filter((item) => item.attachment !== file.attachment);
                component.fileList = newList;
                component.formEntity.attachments = attachments.filter((a) => a.attachment !== file.attachment);
            }
            if (attachment) {
                newList = fileList.filter((item) => item.uid !== file.uid);
                component.fileList = newList;
                component.formEntity.attachments = attachments.filter((a) => a.attachment !== attachment);
            }
        },
        async updateFileOnSuccess(event, file, fileList) {
            const { formEntity } = this;
            const currentFile = file;
            const { response } = currentFile;
            this.fileList = fileList;
            currentFile.src = response.src;
            currentFile.isLoading = false;
            delete response.src;
            formEntity.attachments.push(response);
        },
        onProgressUpload(event, file) {
            // eslint-disable-next-line no-param-reassign
            file.isLoading = true;
        },
        handleErrorFiles(err) {
            this.$notify.error({
                title: 'Upload Error',
                message: err || 'Uploading files error.'
            });
        }
    }
};
