<template>
  <div class="image-deleting">
    <span class="container">
      <span class="label">DELETING...</span>
    </span>
  </div>
</template>
<script>
export default {
  name: 'image-deleting'
};
</script>
<style lang="scss" scoped>
  .image-deleting {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .container {
      text-align: center;
    }

    i {
      font-size: 20px;
      width: 20px;
      margin: 0 auto;
    }

    .label {
      font-weight: bold;
      font-size: 10px;
    }
  }
</style>
