import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { MESSAGES_ROUTE } from '../../../modules/messages/routes';
import { PROJECT_MESSAGES_ROUTE } from '../../../modules/project-messages/routes';
export default defineComponent({
    name: 'message-dashboard-container-slot',
    props: {
        messagesToggleOpenCloseDetailClass: String,
    },
    data: () => {
        return {
            MESSAGES_ROUTE,
            PROJECT_MESSAGES_ROUTE
        };
    },
    computed: {
        ...mapGetters(['isTradesperson', 'isProjectOwner']),
        currentRoute() {
            return this.$route;
        }
    }
});
