<template>
  <div class="image-uploading">
    <span class="container">
      <el-icon><Upload /></el-icon>
      <br>
      <span class="label">UPLOADING...</span>
    </span>
  </div>
</template>
<script>
import { Upload } from '@element-plus/icons';

export default {
  name: 'image-uploading',
  components: {
    Upload
  }
};
</script>
<style lang="scss" scoped>
  .image-uploading {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .container {
      text-align: center;
    }

    i {
      font-size: 20px;
      width: 20px;
      margin: 0 auto;
    }

    .label {
      font-weight: bold;
      font-size: 10px;
    }
  }
</style>
