import moment from 'moment';
import AuthService from '@/core/services/authentication.service';
import ValidationService from '@/core/services/validation.service';
// eslint-disable-next-line no-unused-vars
export const checkDateValidator = (rule, value, cb) => {
    const birthDateMoment = moment(value, 'YYYY-MM-DD', true);
    if (!birthDateMoment.isValid()) {
        return cb(new Error('Invalid Date'));
    }
    return cb();
};
// eslint-disable-next-line no-unused-vars
export const checkPasswordValidator = (rule, value, cb) => {
    if (!value || value.length === 0) {
        return cb(new Error('Password is required.'));
    }
    // eslint-disable-next-line no-useless-escape
    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.\[\]{}()?"!@#%&/\\,><':;|_~`])\S{8,99}$/.test(value)) {
        return cb(new Error('Requires a minimum of 8 total characters, with 1 upper case, 1 lower case and 1 symbol'));
    }
    return cb();
};
// eslint-disable-next-line no-unused-vars
export const checkDateIsBeforeNowValidator = (rule, value, cb) => {
    const today = moment().utc();
    const enteredDate = moment(value).utc();
    if (today.diff(enteredDate, 'days') < 0) {
        return cb(new Error('You must enter a date before current date.'));
    }
    return cb();
};
// eslint-disable-next-line no-unused-vars
export const checkPostCodeValidator = (rule, value, cb) => {
    const postcode = (value || '').trim();
    if (postcode && postcode.length) {
        ValidationService.validatePostcode(postcode)
            .then((response) => {
            if (response) {
                return cb();
            }
            return cb(new Error('Valid Postcode1 is required.'));
        }).catch(() => {
            return cb(new Error('Valid Postcode2 is required.'));
        });
    }
    // return cb(new Error('Valid Postcode3 is required.'));
};
// eslint-disable-next-line no-unused-vars
export const checkPhoneUKValidator = (rule, value, cb) => {
    if (value.length && value != null) {
        ValidationService.validatePhoneNumber(value).then((response) => {
            if (response.valid === false || response.success === false) {
                return cb(new Error('Enter a valid UK phone number.'));
            }
            return cb();
        });
    }
    // return cb(new Error('Enter a valid UK phone number.'));
};
// eslint-disable-next-line no-unused-vars
export const checkFieldWithWhiteSpacesValidator = (rule, value, cb) => {
    const trimmedValue = value ? value.trim() : value;
    if (!value && rule.required) {
        return cb(new Error(rule.errorMsg));
    }
    if (!trimmedValue.length) {
        return cb(new Error('White spaces are not allowed or invalid value.'));
    }
    return cb();
};
// eslint-disable-next-line no-unused-vars
export const checkIfPhoneNumberExist = (rule, value, cb) => {
    if (value.length && value != null) {
        AuthService.authenticatePhoneNumber(value).then((response) => {
            if (response.valid === false || response.success === false) {
                return cb(new Error('Phone number already exist.'));
            }
            return cb();
        });
    }
};
