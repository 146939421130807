/* import {
  Document
} from '@element-plus/icons'; */
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
/* import ImageDynamicLayout from '@/core/components/ui/ImageDynamicLayout.vue';
import ImageFailedLoading from '@/core/components/ui/ImageFailedLoading.vue';
import ImageLoading from '@/core/components/ui/ImageLoading.vue'; */
import { API_URL } from '../../../config';
import { MESSAGES_STORE } from '../../../store/modules/messages';
import { ACTIVE_MESSAGE_INPUT_WIDGETS, AUTH_TOKEN, FILE_UPLOAD_ACCEPTED_FILE_TYPES } from '../../constants';
import { getLocalStorageWithExpiry } from '../../utils/common';
export default defineComponent({
    name: 'message-input-widgets-component',
    components: {
    /* ImageDynamicLayout,
    ImageFailedLoading,
    ImageLoading,
    Document */
    },
    emits: ['submit-uploading-attachment', 'submit-uploaded-attachment', 'submit-widget-action'],
    props: {
        userMessageRoomId: Number,
    },
    data: () => {
        return {
            activeWidgets: [],
            apiUrl: API_URL,
        };
    },
    computed: {
        ...mapGetters(MESSAGES_STORE, ['getAvailableWidgets']),
        uploadHeaders() {
            const token = getLocalStorageWithExpiry(AUTH_TOKEN);
            return {
                Authorization: `Bearer ${token}`
            };
        },
        getActivatedWidgets() {
            return this.getAvailableWidgets.filter((widget) => this.activeWidgets.includes(widget.name));
        },
        getFileTypes() {
            return FILE_UPLOAD_ACCEPTED_FILE_TYPES.join(',');
        }
    },
    created() {
        this.activeWidgets = ACTIVE_MESSAGE_INPUT_WIDGETS;
    },
    methods: {
        doSubmit() {
            this.$emit('submit-widget-action');
        },
        updateFileOnSuccess(response) {
            this.$emit('submit-uploaded-attachment', response);
        },
        onProgressUpload(event, file) {
            this.$emit('submit-uploading-attachment', event, file);
        },
        fileIsImage(file) {
            const { name: filename } = file;
            const imageTypes = ['png', 'jpeg', 'jpg', 'tiff', 'bmp', 'jbig', 'gif', 'GIF', 'PNG', 'JPEG', 'JPG'];
            if (!filename)
                return false;
            const extension = filename.split('.')[filename.split('.').length - 1];
            return imageTypes.includes(extension.toLowerCase());
        },
    }
});
