import { mapActions, mapState } from 'vuex';
import { checkFieldWithWhiteSpacesValidator, checkPostCodeValidator } from '@/core/validators';
import { PROJECTS_STORE } from '@/store/modules/projects';
export default {
    name: 'project-information-form',
    props: {
        project: {
            type: Object,
            required: true,
        }
    },
    data: () => {
        return {
            formRules: {
                name: [
                    {
                        required: true, validator: checkFieldWithWhiteSpacesValidator, errorMsg: 'Project name is required.', trigger: 'blur'
                    },
                ],
                postcode: [
                    { required: true, validator: checkPostCodeValidator, trigger: 'blur' },
                ],
                categories: [
                    { required: true, message: 'Project category is required.', trigger: 'change' },
                ],
                description: [
                    {
                        required: true, validator: checkFieldWithWhiteSpacesValidator, errorMsg: 'Project description is required.', trigger: 'blur'
                    },
                ],
            },
            clonedCategories: [],
            getProjectCategories: [],
            subCatCount: {},
        };
    },
    created() {
        this.initialize();
    },
    computed: {
        ...mapState(PROJECTS_STORE, {
            categories: (state) => {
                if (state.categories.length) {
                    return state.categories.map((category) => {
                        return {
                            value: category.id,
                            label: category.poLabel,
                            children: category.subCategories.map((sub) => {
                                return { value: sub.id, label: sub.label };
                            }),
                        };
                    });
                }
                return [];
            },
        }),
        formEntity() {
            return this.project;
        },
        subCategoryCount() {
            const subCategoryCount = {};
            this.categories.forEach((category) => {
                subCategoryCount[category.value] = category.children.length || 0;
            });
            return subCategoryCount;
        },
        formattedProjectCategories() {
            return this.formEntity.categories.map((c) => {
                if (c?.subCategories?.length) {
                    return [c.id, c.subCategories.map((sub) => sub.id)[0]];
                }
                return [c.id];
            });
        }
    },
    methods: {
        ...mapActions(PROJECTS_STORE, ['getCategories']),
        async initialize() {
            await this.getCategories();
            const projectCategories = JSON.parse(JSON.stringify(this.project.categories));
            const mappedProjectCategories = [];
            if (projectCategories) {
                projectCategories.forEach((category) => {
                    const mainCategory = category.id;
                    if (category.subCategories.length) {
                        category.subCategories.forEach((subcategory) => {
                            const arrCategory = [mainCategory, subcategory.id];
                            mappedProjectCategories.push(arrCategory);
                        });
                    }
                });
            }
            this.clonedCategories = mappedProjectCategories;
            this.getProjectCategories = mappedProjectCategories;
            this.formEntity.categoriesForSubmission = mappedProjectCategories;
        },
        trimField(val, field) {
            this.formEntity[field] = val ? val.trim() : val;
        },
        categoryChange() {
            const selectedCategories = {};
            const clonedCategories = JSON.parse(JSON.stringify(this.getProjectCategories));
            this.formattedProjectCategories.forEach((category) => {
                if (!selectedCategories[category[0]]) {
                    selectedCategories[category[0]] = [];
                }
                selectedCategories[category[0]].push(category[1]);
            });
            const catsWithAllSelectedSubCat = {};
            for (const [parentCategory, subCategories] of Object.entries(selectedCategories)) {
                if (subCategories.length === this.subCategoryCount[parentCategory]) {
                    catsWithAllSelectedSubCat[parentCategory] = subCategories.length;
                }
            }
            if (Object.keys(catsWithAllSelectedSubCat).length > 0) {
                for (let i = clonedCategories.length - 1; i >= 0; i--) {
                    const cat = clonedCategories[i];
                    const parentId = cat[0];
                    if (catsWithAllSelectedSubCat[parentId] && catsWithAllSelectedSubCat[parentId] > (this.subCatCount[parentId] === 1 ? 0 : 1)) {
                        /* cat[1] = 0 */
                        clonedCategories.splice(i, 1);
                        // eslint-disable-next-line no-plusplus
                        catsWithAllSelectedSubCat[parentId]--;
                    }
                }
            }
            setTimeout(() => {
                this.getProjectCategories = clonedCategories;
                this.clonedCategories = clonedCategories;
                this.formEntity.categoriesForSubmission = clonedCategories;
            });
        },
        getSubCategory(ids) {
            let subCategory = null;
            const parentCategory = this.categories.find((c) => ids.length && c.value === ids[0]);
            if (parentCategory && parentCategory.children) {
                if (ids[1]) {
                    subCategory = parentCategory.children.find((sub) => sub.value === ids[1]);
                    return subCategory.label;
                }
                return parentCategory.label;
            }
            return subCategory;
        },
        removeSubCategory(data) {
            const categories = JSON.parse(JSON.stringify(this.getProjectCategories));
            if (data[1] !== 0) {
                const index = categories.findIndex((category) => {
                    return category[0] === data[0] && category[1] === data[1];
                });
                categories.splice(index, 1);
            }
            else {
                for (let i = categories.length - 1; i >= 0; i--) {
                    const category = categories[i];
                    if (category[0] === data[0]) {
                        categories.splice(i, 1);
                    }
                }
            }
            const newCategories = categories;
            this.getProjectCategories = newCategories;
            this.categoryChange();
        },
        filterOptions(node, keyword) {
            return node.text.toLowerCase().includes(keyword.toLowerCase());
        }
    }
};
