import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import UserThumbnail from '@/core/components/ui/UserThumbnail.vue';
import { PROJECT_OVERVIEW_ROUTE } from '../../../modules/project-overview/routes';
import { AUTHENTICATION_STORE } from '../../../store/modules/authentication';
export default defineComponent({
    name: 'message-list-detail-box-component',
    components: {
        UserThumbnail
    },
    props: {
        userMessageRoom: Object,
        showViewProjectButton: Boolean,
    },
    computed: {
        ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),
        ...mapGetters(['isTradesperson', 'isProjectOwner']),
        member() {
            let member = this.userMessageRoom.fromUser && this.userMessageRoom.fromUser.id !== this.authenticatedUser?.userId
                ? this.userMessageRoom.fromUser : this.userMessageRoom.toUser;
            if (!member) {
                member = this.userMessageRoom?.project?.projectCreator;
            }
            return member;
        },
        memberIsOnline() {
            if (this.userMessageRoom?.members?.length) {
                // eslint-disable-next-line radix
                return this.userMessageRoom.members.find((m) => parseInt(m.id) === this.member.id);
            }
            return false;
        },
        memberFullName() {
            return `${this.member.firstName} ${this.member.lastName}`;
        },
    },
    methods: {
        viewProject() {
            this.$router.push({ name: PROJECT_OVERVIEW_ROUTE, params: { projectRefId: this.userMessageRoom?.project?.refId } });
        }
    }
});
